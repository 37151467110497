import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function TeamIndex({ isAdmin, sport }) {
    const [teams, setTeams] = useState([]);
    const [newTeam, setNewTeam] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [editTeam, setEditTeam] = useState('');

    const fetchTeams = useCallback(async () => {
        if (!sport) {
            setTeams([]);
            return;
        }
        try {
            const response = await axios.get(`${API_BASE_URL}/api/teams/${sport}`);
            if (response.data && Array.isArray(response.data.data)) {
                setTeams(response.data.data);
            } else {
                console.error('Unexpected data format:', response.data);
                setTeams([]);
            }
        } catch (error) {
            console.error('팀 목록을 가져오는 중 오류 발생:', error);
            setTeams([]);
        }
    }, [sport]);

    useEffect(() => {
        fetchTeams();
    }, [fetchTeams]);

    const handleAddTeam = async () => {
        if (newTeam.trim()) {
            try {
                console.log('팀 추가 요청 보내는 중:', newTeam.trim());
                const response = await axios.post(`${API_BASE_URL}/api/teams/${sport}`, { teamName: newTeam.trim() });
                console.log('서버 응답:', response.data);

                if (response.data && response.data.success) {
                    setTeams(response.data.teams);
                    setNewTeam('');
                } else {
                    console.error('예상치 못한 서버 응답:', response.data);
                    alert(response.data.message || '팀 추가에 실패했습니다.');
                }
            } catch (error) {
                console.error('팀 추가 중 오류 발생:', error);
                if (error.response) {
                    console.error('서버 응답:', error.response.data);
                    alert(`팀 추가 실패: ${error.response.data.message || '알 수 없는 오류'}`);
                } else if (error.request) {
                    console.error('응답 없음:', error.request);
                    alert('서버로부터 응답이 없습니다.');
                } else {
                    console.error('요청 오류:', error.message);
                    alert(`요청 오류: ${error.message}`);
                }
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleAddTeam();
        }
    };

    const handleEditTeam = (index) => {
        setEditIndex(index);
        setEditTeam(teams[index]);
    };

    const handleUpdateTeam = async () => {
        try {
            const response = await axios.put(`${API_BASE_URL}/api/teams/${sport}`, { oldTeam: teams[editIndex], newTeam: editTeam });
            if (response.data && response.data.message) {
                const updatedTeams = [...teams];
                updatedTeams[editIndex] = editTeam;
                setTeams(updatedTeams); // 로컬 상태 업데이트
                setEditIndex(null);
                setEditTeam('');
            } else {
                console.error('팀 수정 실패:', response.data);
                alert('팀 수정에 실패했습니다.');
            }
        } catch (error) {
            console.error('팀 수정 중 오류 발생:', error);
            alert('팀 수정 중 오류가 발생했습니다.');
        }
    };

    const handleDeleteTeam = async (index) => {
        try {
            const response = await axios.delete(`${API_BASE_URL}/api/teams/${sport}`, { data: { team: teams[index] } });
            if (response.data && response.data.message) {
                const updatedTeams = teams.filter((_, i) => i !== index);
                setTeams(updatedTeams); // 로컬 상태 업데이트
            } else {
                console.error('팀 삭제 실패:', response.data);
                alert('팀 삭제에 실패했습니다.');
            }
        } catch (error) {
            console.error('팀 삭제 중 오류 발생:', error);
            alert('팀 삭제 중 오류가 발생했습니다.');
        }
    };

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {teams && teams.length > 0 ? (
                teams.map((team, index) => (
                    <div 
                        key={index} 
                        style={{ 
                            flex: '1 0 48%', 
                            marginBottom: '10px', 
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #ccc', 
                            paddingBottom: '10px' 
                        }}
                    >
                        {editIndex === index ? (
                            <>
                                <input 
                                    type="text" 
                                    value={editTeam} 
                                    onChange={(e) => setEditTeam(e.target.value)} 
                                    style={{ flex: 1, marginRight: '10px' }}
                                />
                                <button 
                                    onClick={handleUpdateTeam} 
                                    style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }}
                                >
                                    저장
                                </button>
                                <button 
                                    onClick={() => setEditIndex(null)} 
                                    style={{ padding: '5px 10px', cursor: 'pointer' }}
                                >
                                    취소
                                </button>
                            </>
                        ) : (
                            <>
                                <span style={{ flex: 1 }}>{team}</span>
                                {isAdmin && (
                                    <>
                                        <button 
                                            onClick={() => handleEditTeam(index)} 
                                            style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }}
                                        >
                                            수정
                                        </button>
                                        <button 
                                            onClick={() => handleDeleteTeam(index)} 
                                            style={{ padding: '5px 10px', cursor: 'pointer' }}
                                        >
                                            삭제
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                ))
            ) : (
                <p>이 종목에 대한 팀이 없습니다.</p>
            )}
            {isAdmin && (
                <div style={{ marginTop: '0px', width: '49.5%', display: 'flex', alignItems: 'center' }}>
                    <input 
                        type="text" 
                        value={newTeam} 
                        onChange={(e) => setNewTeam(e.target.value)} 
                        onKeyDown={handleKeyDown}
                        placeholder="새 팀 이름 입력" 
                        style={{ flexGrow: 1, marginRight: '10px', padding: '5px 10px' }}
                    />
                    <button 
                        onClick={handleAddTeam} 
                        style={{ padding: '5px 10px', cursor: 'pointer', whiteSpace: 'nowrap' }}
                    >
                        추가
                    </button>
                </div>
            )}
        </div>
    );
}

export default TeamIndex;
