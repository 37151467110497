import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_BASE_URL = 'http://localhost:5000'; // 실제 서버 URL

function Ranking({ isAdmin, sport }) {
    const [rankings, setRankings] = useState(['', '', '']); 

    useEffect(() => {
        fetchRankings();
    }, [sport]);

    const fetchRankings = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/rankings/${sport}`);
            console.log('서버 응답:', response.data);
            if (response.data && response.data.rankings && response.data.rankings.length > 0) {
                setRankings(response.data.rankings);
            } else {
                setRankings(['', '', '']); 
            }
        } catch (error) {
            console.error('랭킹 정보를 불러오는데 실패했습니다:', error.response ? error.response.data : error.message);
            setRankings(['', '', '']);
        }
    };

    // 팀명을 업데이트하는 함수
    const handleInputChange = (index, value) => {
        const updatedRankings = [...rankings];
        updatedRankings[index] = value;
        setRankings(updatedRankings);
    };

    const handleSaveRanking = async () => {
        try {
            const response = await axios.post(`${API_BASE_URL}/api/rankings/${sport}`, { rankings });
            console.log('서버 응답:', response.data);
            alert('순위가 저장되었습니다!');
            fetchRankings();
        } catch (error) {
            console.error('랭킹 저장에 실패했습니다:', error.response ? error.response.data : error.message);
            alert('랭킹 저장에 실패했습니다.');
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '30px', fontSize: '30px', fontWeight: 'bold', color: '#333' }}>🏆순위</h2>
            
            {rankings.map((team, index) => (
                <div 
                    key={index} 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                        padding: '15px',
                        border: '2px solid #ddd',
                        borderRadius: '10px',
                        background: index === 0 
                            ? 'linear-gradient(45deg, #ffd700, #ffeb3b)' 
                            : index === 1
                            ? 'linear-gradient(45deg, #c0c0c0, #e0e0e0)'
                            : index === 2
                            ? 'linear-gradient(45deg, #cd7f32, #deb887)'
                            : '#ffffff',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                >
                    <span style={{ fontWeight: 'bold', fontSize: '20px', color: index === 0 ? '#b8860b' : index === 1 ? '#888' : '#8b4513' }}>
                        {index === 0 ? '🥇 1등' : index === 1 ? '🥈 2등' : index === 2 ? '🥉 3등' : `${index + 1}등`}
                    </span>
                    {isAdmin ? (
                        <input 
                            type="text" 
                            value={team} 
                            onChange={(e) => handleInputChange(index, e.target.value)} 
                            placeholder={`팀 이름을 입력하세요 (${index + 1}등)`}
                            style={{ padding: '8px 12px', width: '220px', flexGrow: 1, marginLeft: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
                        />
                    ) : (
                        <span style={{ fontSize: '18px', marginLeft: '10px', fontWeight: '500' }}>{team || '경기중'}</span>
                    )}
                </div>
            ))}
            
            {/* 저장버튼 */}
            {isAdmin && (
                <button 
                    onClick={handleSaveRanking} 
                    style={{ 
                        padding: '10px 20px', 
                        cursor: 'pointer', 
                        marginTop: '10px', 
                        marginBottom: '20px', 
                        marginLeft: '10px',
                        marginRight: '10px',
                        backgroundColor: '#28a745', 
                        color: '#fff', 
                        border: 'none', 
                        borderRadius: '5px', 
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                    }}
                >
                    순위 저장
                </button>
            )}
        </div>
    );
}

export default Ranking;
