import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function GroupBracket({ sport, rows, columns, isAdmin = true }) {
  const [bracket, setBracket] = useState([]);
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sportId, setSportId] = useState(null);
  const [savedBracket, setSavedBracket] = useState({ teams: [], editablePositions: {} });

  const fetchTeams = useCallback(async () => {
    if (!sport) {
      setTeams([]);
      return;
    }
    try {
      console.log('Fetching teams for sport:', sport);
      const response = await axios.get(`${API_BASE_URL}/api/teams/${sport}`);
      console.log('API response:', response.data);

      if (response.data && Array.isArray(response.data.data)) {
        setTeams(response.data.data);
      } else {
        console.error('Unexpected data format:', response.data);
        setTeams([]);
      }
    } catch (error) {
      console.error('팀 목록을 가져오는 중 오류 발생:', error);
      setTeams([]);
    }
  }, [sport]);

  const fetchSavedBracket = useCallback(async () => {
    if (!sport) return;
    setIsLoading(true);
    
    console.log('fetchSavedBracket 함수 시작');
    console.log('현재 스포츠:', sport);
    
    try {
      console.log(`대진표 가져오기 요청: ${API_BASE_URL}/api/getBracket/${sport}`);
      const response = await axios.get(`${API_BASE_URL}/api/getBracket/${sport}`);
      console.log('===');
      console.log(response.data);
      console.log(response.status);
      
      // response.data.bracket이 없고, response.data.teams 로 데이터가 옴
      if (response.data) {
        console.log('유효한 대진표 데이터 수신');
        console.log('수신된 대진표:', response.data.data.teams);
        setSavedBracket(response.data.data);
        setBracket(response.data.data.teams || []);
        console.log('상태 업데이트 완료');
      } else {
        console.error('예상치 못한 서버 응답:', response.data);
        console.log('빈 대진표로 상태 초기화');
        setSavedBracket({ teams: [], editablePositions: {} });
        setBracket([]);
      }
    } catch (error) {
      console.log(error);
      console.error('대진표를 가져오는 중 오류 발생:', error);
      if (error.response) {
        console.error('오류 응답:', error.response.data);
        console.error('오류 상태:', error.response.status);
      } else if (error.request) {
        console.error('응답을 받지 못했습니다:', error.request);
      } else {
        console.error('오류 메시지:', error.message);
      }
    } finally {

      // 로딩 완료 처리로 변경
      setIsLoading(false);
      console.log('fetchSavedBracket 함수 종료');
    }
  }, [sport]);

  const fetchSportId = useCallback(async () => {
    if (!sport) return;
    try {
      console.log('Fetching sport ID for:', sport);
      const response = await axios.get(`${API_BASE_URL}/api/sports/${sport}`);
      console.log('Sport ID API response:', response.data);
      if (response.data && response.data.id) {
        setSportId(response.data.id);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('스포츠 ID를 가져오는 중 오류 발생:', error);
    }
  }, [sport]);

  useEffect(() => {
    if (sport) {
      fetchTeams();
      fetchSavedBracket();
      fetchSportId();
    }
  }, [sport, fetchTeams, fetchSavedBracket, fetchSportId]);

  // 팀을 랜덤하게 섞는 함수
  const shuffleTeams = (teams) => {
    let shuffled = [...teams];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // 대진표 생성 (동적 행과 열 값에 따라 테이블 생성)
  const generateBracket = (teams) => {
    const shuffledTeams = shuffleTeams(teams);
    const bracketTable = [];

    // 행과 열 값에 따라 테이블 셀을 생성
    for (let i = 0; i < rows; i++) {
      const rowTeams = [];
      for (let j = 0; j < columns; j++) {
        const teamIndex = i * columns + j;
        rowTeams.push(shuffledTeams[teamIndex] || '-');
      }
      bracketTable.push(rowTeams);
    }
    return bracketTable;
  };

  const handleShuffleTeams = async () => {
    if (isAdmin && teams.length) {
      const newBracket = generateBracket(teams);
      setBracket(newBracket);
      
      // DB에 저장
      try {
        const bracketData = {
          sport_id: sportId,
          teams: newBracket,
          editablePositions: savedBracket.editablePositions
        };
        const response = await axios.post(`${API_BASE_URL}/api/saveBracket/${sport}`, bracketData);
        
        if (response.data.success) {
          console.log('대진표가 새로 생성되고 DB에 저장되었습니다.');
          setSavedBracket({ ...savedBracket, teams: newBracket });
        } else {
          console.error('대진표 저장 실패:', response.data.message);
        }
      } catch (error) {
        console.error('대진표 저장 중 오류 발생:', error);
      }
    } else if (!isAdmin) {
      console.log('관리자 권한이 없습니다.');
    } else {
      console.log('팀 데이터가 없습니다.');
    }
  };

  const handleSaveBracket = async () => {
    if (!isAdmin) {
      console.log('관리자 권한이 없습니다.');
      return;
    }

    if (bracket.length === 0) {
      alert('저장할 대진표가 없습니다. 먼저 대진표를 생성해주세요.');
      return;
    }

    if (!sportId) {
      alert('스포츠 ID가 설정되지 않았습니다. 팀 데이터를 다시 불러와 주세요.');
      return;
    }

    try {
      const bracketData = {
        sport_id: sportId,
        teams: bracket,
        editablePositions: savedBracket.editablePositions
      };
      console.log('저장할 데이터:', bracketData);
      const response = await axios.post(`${API_BASE_URL}/api/saveBracket/${sport}`, bracketData);
      console.log('서버 응답:', response.data);
      
      if (response.data.success) {
        alert('대진표가 성공적으로 저장되었습니다.');
      } else {
        alert(`대진표 저장 실패: ${response.data.message || '알 수 없는 오류'}`);
      }
    } catch (error) {
      console.error('대진표 저장 중 오류 발생:', error);
      alert(`대진표 저장 중 오류 발생: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDeleteBracket = () => {
    if (!isAdmin) {
      console.log('관리자 권한이 없습니다.');
      return;
    }

    if (window.confirm('정말 대진표를 삭제하시겠습니까?')) {
      const emptyBracket = { teams: [], editablePositions: {} };
      setSavedBracket(emptyBracket);
      setBracket([]);
      localStorage.removeItem(`savedBracket_${sport}`);
      alert('대진표가 삭제되었습니다.');
    }
  };

  if (isLoading) {
    return <div>팀 데이터를 불러오는 중...</div>;
  }

  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>대진표</h2>
      <div>
        {isAdmin && (
          <>
            <button 
              onClick={handleShuffleTeams} 
              style={{ 
                padding: '10px 20px', 
                cursor: 'pointer', 
                marginTop: '10px', 
                marginBottom: '20px', 
                marginLeft: '10px',
                marginRight: '10px',
                backgroundColor: '#ffc107', 
                color: '#fff', 
                border: 'none', 
                borderRadius: '5px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
              }}
            >
              대진표 생성
            </button>

            <button 
              onClick={handleSaveBracket} 
              style={{ 
                padding: '10px 20px', 
                cursor: 'pointer', 
                marginTop: '10px', 
                marginBottom: '20px', 
                marginLeft: '10px',
                marginRight: '10px',
                backgroundColor: '#28a745', 
                color: '#fff', 
                border: 'none', 
                borderRadius: '5px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
              }}
            >
              대진표 저장
            </button>

            <button 
              onClick={handleDeleteBracket} 
              style={{ 
                padding: '10px 20px', 
                cursor: 'pointer', 
                marginTop: '10px', 
                marginBottom: '20px', 
                marginLeft: '10px',
                marginRight: '10px',
                backgroundColor: '#dc3545', 
                color: '#fff', 
                border: 'none', 
                borderRadius: '5px', 
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
              }}
            >
              대진표 삭제
            </button>
          </>
        )}
      </div>



      <table border="1" style={{ width: '100%', textAlign: 'center' }}>
        <thead>
          <tr>
            {Array.from({ length: columns }).map((_, index) => (
              <th key={index}>{index + 1} 조</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {bracket.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((team, columnIndex) => (
                <td key={columnIndex}>{team}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default GroupBracket;
