import React from 'react';

function Daegin({ teams = [], editablePositions = {} }) {
  const finals = [
      { x :560, y: 400, name: "" },
      { x :560, y: 650, name: "" },
    ];
    
    const line1_16 = [
      { x: 50, y: 50, name: "" },
      { x: 50, y: 100, name: "" },
      { x: 50, y: 150, name: "" },
      { x: 50, y: 200, name: "" },
      { x: 50, y: 250, name: "" },
      { x: 50, y: 300, name: "" },
      { x: 50, y: 350, name: "" },
      { x: 50, y: 400, name: "" },
      { x: 1067, y: 50, name: "" },
      { x: 1067, y: 100, name: "" },
      { x: 1067, y: 150, name: "" },
      { x: 1067, y: 200, name: "" },
      { x: 1067, y: 250, name: "" },
      { x: 1067, y: 300, name: "" },
      { x: 1067, y: 350, name: "" },
      { x: 1067, y: 400, name: "" },
    ]
  
    const line1_8 = [
      { x: 200, y: 75, name: "" },
      { x: 200, y: 175, name: "" },
      { x: 200, y: 275, name: "" },
      { x: 200, y: 375, name: "" },
      { x: 917, y: 75, name: "" },
      { x: 917, y: 175, name: "" },
      { x: 917, y: 275, name: "" },
      { x: 917, y: 375, name: "" },
    ]
  
    const line1_4 = [
      { x: 350, y: 125, name: "" },
      { x: 350, y: 325, name: "" },
      { x: 767, y: 125, name: "" },
      { x: 767, y: 325, name: "" },
    ]
  
    const semifinal1 = [
      { x: 500, y: 225, name: "" },
      { x: 617, y: 225, name: "" },
    ]
  
    const line2_16 = [
      { x: 50, y: 650, name: "" },
      { x: 50, y: 700, name: "" },
      { x: 50, y: 750, name: "" },
      { x: 50, y: 800, name: "" },
      { x: 50, y: 850, name: "" },
      { x: 50, y: 900, name: "" },
      { x: 50, y: 950, name: "" },
      { x: 50, y: 1000, name: "" },
      { x: 1067, y: 650, name: "" },
      { x: 1067, y: 700, name: "" },
      { x: 1067, y: 750, name: "" },
      { x: 1067, y: 800, name: "" },
      { x: 1067, y: 850, name: "" },
      { x: 1067, y: 900, name: "" },
      { x: 1067, y: 950, name: "" },
      { x: 1067, y: 1000, name: "" },
    ]
  
    const line2_8 = [
      { x: 200, y: 675, name: "" },
      { x: 200, y: 775, name: "" },
      { x: 200, y: 875, name: "" },
      { x: 200, y: 975, name: "" },
      { x: 917, y: 675, name: "" },
      { x: 917, y: 775, name: "" },
      { x: 917, y: 875, name: "" },
      { x: 917, y: 975, name: "" },
    ]
  
    const line2_4 = [
      { x: 350, y: 725, name: "" },
      { x: 350, y: 925, name: "" },
      { x: 767, y: 725, name: "" },
      { x: 767, y: 925, name: "" },
    ]
  
    const semifinal2 = [
      { x: 500, y: 825, name: "" },
      { x: 617, y: 825, name: "" },
    ]
  
    const assignTeamPositions = (teams) => {
      const leftX = 50;
      const rightX = 1067;
      const topY = [50, 250, 150, 350, 100, 300, 200, 400];
      const bottomY = [650, 850, 750, 950, 700, 900, 800, 1000];
      
      const positions = [];
      const teamCount = teams.length;

      console.log(teams);
      
      if (teamCount <= 8) {
        // 1~8개 팀: 모두 좌상단
        for (let i = 0; i < teamCount; i++) {
          positions.push({ x: leftX, y: topY[i], name: teams[i] });
        }
      } else if (teamCount <= 16) {
        // 9~16개 팀: 좌상단, 우상단
        const leftCount = Math.ceil(teamCount / 2);
        for (let i = 0; i < leftCount; i++) {
          positions.push({ x: leftX, y: topY[i], name: teams[i] });
        }
        for (let i = leftCount; i < teamCount; i++) {
          positions.push({ x: rightX, y: topY[i - leftCount], name: teams[i] });
        }
      } else if (teamCount <= 24) {
        // 17~24개 팀: 알고리즘 일부 수정
        const sectionCount = Math.min(6, Math.ceil(teamCount / 4));
        for (let i = 0; i < sectionCount; i++) {
          if (i < teams.length) positions.push({ x: leftX, y: topY[i], name: teams[i] });
        }
        for (let i = 0; i < sectionCount; i++) {
          if (sectionCount + i < teams.length) positions.push({ x: rightX, y: topY[i], name: teams[sectionCount + i] });
        }
        for (let i = 0; i < sectionCount; i++) {
          if (2 * sectionCount + i < teams.length) positions.push({ x: leftX, y: bottomY[i], name: teams[2 * sectionCount + i] });
        }
        for (let i = 0; i < sectionCount; i++) {
          if (3 * sectionCount + i < teams.length) positions.push({ x: rightX, y: bottomY[i], name: teams[3 * sectionCount + i] });
        }
      } else {
        // 25~32개 팀
        const sectionCount = Math.ceil(teamCount / 4);
        for (let i = 0; i < sectionCount; i++) {
          if (i < teams.length) positions.push({ x: leftX, y: topY[i], name: teams[i] });
        }
        for (let i = 0; i < sectionCount; i++) {
          if (sectionCount + i < teams.length) positions.push({ x: leftX, y: bottomY[i], name: teams[sectionCount + i] });
        }
        for (let i = 0; i < sectionCount; i++) {
          if (2 * sectionCount + i < teams.length) positions.push({ x: rightX, y: topY[i], name: teams[2 * sectionCount + i] });
        }
        for (let i = 0; i < sectionCount; i++) {
          if (3 * sectionCount + i < teams.length) positions.push({ x: rightX, y: bottomY[i], name: teams[3 * sectionCount + i] });
        }
      }
      
      return positions;
    };
  
    const teamPositions = assignTeamPositions(teams);
  
    const generateLines = () => {
        const lines = [];
        // 좌상단 1차전 -> 2차전 선들
        for (let i = 0; i < 4; i++) {
            lines.push(`150,${65 + i * 100} 175,${65 + i * 100} 175,${90 + i * 100} 200,${90 + i * 100}`);
            lines.push(`150,${115 + i * 100} 175,${115 + i * 100} 175,${90 + i * 100} 200,${90 + i * 100}`);
        }
        // 좌상단 2차전 -> 3차전 선들
        for (let i = 0; i < 2; i++) {
            lines.push(`300,${90 + i * 200} 325,${90 + i * 200} 325,${140 + i * 200} 350,${140 + i * 200}`);
            lines.push(`300,${190 + i * 200} 325,${190 + i * 200} 325,${140 + i * 200} 350,${140 + i * 200}`);
        }
        // 좌상단 3차전 -> 준결승 선들
        lines.push("450,140 475,140 475,240 500,240");
        lines.push("450,340 475,340 475,240 500,240");
  
        // 우상단 선들 (대칭)
        for (let i = 0; i < 4; i++) {
            lines.push(`1067,${65 + i * 100} 1042,${65 + i * 100} 1042,${90 + i * 100} 1017,${90 + i * 100}`);
            lines.push(`1067,${115 + i * 100} 1042,${115 + i * 100} 1042,${90 + i * 100} 1017,${90 + i * 100}`);
        }
        for (let i = 0; i < 2; i++) {
            lines.push(`917,${90 + i * 200} 892,${90 + i * 200} 892,${140 + i * 200} 867,${140 + i * 200}`);
            lines.push(`917,${190 + i * 200} 892,${190 + i * 200} 892,${140 + i * 200} 867,${140 + i * 200}`);
        }
        lines.push("767,140 742,140 742,240 717,240");
        lines.push("767,340 742,340 742,240 717,240");
  
        // 하단 부분 선들 (위와 유사하지만 y 좌표 600 증가)
        // 좌하단 1차전 -> 2차전 선들
        for (let i = 0; i < 4; i++) {
            lines.push(`150,${665 + i * 100} 175,${665 + i * 100} 175,${690 + i * 100} 200,${690 + i * 100}`);
            lines.push(`150,${715 + i * 100} 175,${715 + i * 100} 175,${690 + i * 100} 200,${690 + i * 100}`);
        }
        // 좌하단 2차전 -> 3차전 선들
        for (let i = 0; i < 2; i++) {
            lines.push(`300,${690 + i * 200} 325,${690 + i * 200} 325,${740 + i * 200} 350,${740 + i * 200}`);
            lines.push(`300,${790 + i * 200} 325,${790 + i * 200} 325,${740 + i * 200} 350,${740 + i * 200}`);
        }
        // 좌하단 3차전 -> 준결승 선들
        lines.push("450,740 475,740 475,840 500,840");
        lines.push("450,940 475,940 475,840 500,840");
  
        // 우하단 선들 (대칭)
        for (let i = 0; i < 4; i++) {
            lines.push(`1067,${665 + i * 100} 1042,${665 + i * 100} 1042,${690 + i * 100} 1017,${690 + i * 100}`);
            lines.push(`1067,${715 + i * 100} 1042,${715 + i * 100} 1042,${690 + i * 100} 1017,${690 + i * 100}`);
        }
        for (let i = 0; i < 2; i++) {
            lines.push(`917,${690 + i * 200} 892,${690 + i * 200} 892,${740 + i * 200} 867,${740 + i * 200}`);
            lines.push(`917,${790 + i * 200} 892,${790 + i * 200} 892,${740 + i * 200} 867,${740 + i * 200}`);
        }
        lines.push("767,740 742,740 742,840 717,840");
        lines.push("767,940 742,940 742,840 717,840");
        // 중앙 결승전 선들
        lines.push("600,240 617,240", "600,840 617,840", "608,240 608,840");
  
        return lines;
    };
  
    const lines = generateLines();
  
    const renderRect = (item, index) => (
      <g key={index}>
        <rect x={item.x} y={item.y} width="100" height="30" fill="lightgray" />
        <text x={item.x + 50} y={item.y + 20} fill="black" textAnchor="middle">
          {editablePositions[`${item.x},${item.y}`] || item.name}
        </text>
      </g>
    );
  
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 1200 1200"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
        >
            {lines.map((points, index) => (
                <polyline key={index} points={points} stroke="black" fill="none" />
            ))}
            {semifinal1.map(renderRect)}
            {line1_4.map(renderRect)}
            {line1_8.map(renderRect)}
            {line1_16.map(renderRect)}
            {semifinal2.map(renderRect)}
            {line2_4.map(renderRect)}
            {line2_8.map(renderRect)}
            {line2_16.map(renderRect)}
            {finals.map(renderRect)}
            {teamPositions.map((item, index) => (
              <g key={`team-${index}`}>
                <rect x={item.x} y={item.y} width="100" height="30" fill="lightgray" />
                <text x={item.x + 50} y={item.y + 20} fill="black" textAnchor="middle">
                  {editablePositions[`${item.x},${item.y}`] || item.name}
                </text>
              </g>
            ))}
            <g>
                <rect x="567" y="525" width="80" height="30" fill="white" />
                <text x="607" y="545" fill="black" fontSize="20" textAnchor="middle">VS</text>
            </g>
        </svg>
    );
  }

  const TournamentBracketSVG = Daegin;

  export default TournamentBracketSVG;