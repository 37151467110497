// src/components/Layout.js
import React from 'react';

function Layout({ children }) {
    return (
        <div style={{ padding: '20px' }}>
            <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px' }}>
                {children}
            </div>
        </div>
    );
}

export default Layout;
