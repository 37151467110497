import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import TournamentBracketSVG from '../components/contents/TournamentBracketSVG';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

export default function ShuffleTeams({ sport, isAdmin }) {
  const [teams, setTeams] = useState([]);
  const [shuffledTeams, setShuffledTeams] = useState([]);
  const [savedBracket, setSavedBracket] = useState({ teams: [], editablePositions: {} });
  const [isEditing, setIsEditing] = useState(false);
  const [sportId, setSportId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editablePositions, setEditablePositions] = useState({});

  const fetchTeams = useCallback(async () => {
    if (!sport) {
      setTeams([]);
      return;
    }
    try {
      console.log('Fetching teams for sport:', sport);
      const response = await axios.get(`${API_BASE_URL}/api/teams/${sport}`);
      console.log('API response:', response.data);

      if (response.data && Array.isArray(response.data.data)) {
        setTeams(response.data.data);
      } else {
        console.error('Unexpected data format:', response.data);
        setTeams([]);
      }
    } catch (error) {
      console.error('팀 목록을 가져오는 중 오류 발생:', error);
      setTeams([]);
    }
  }, [sport]);

  const fetchSavedBracket = useCallback(async () => {
    if (!sport) return;
    setIsLoading(true);
    const localStorageKey = `savedBracket_${sport}`;
    const cachedBracket = false;
    // const cachedBracket = localStorage.getItem(localStorageKey);
    
    if (cachedBracket) {
      const parsedBracket = JSON.parse(cachedBracket);
      setSavedBracket(parsedBracket);
      setShuffledTeams(parsedBracket.teams || []);
      setEditablePositions(parsedBracket.editablePositions || {});
      setIsLoading(false);
    } else {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/getBracket/${sport}`);
        
        // response data 만 체크하도록 변경
        if (response.data) {

          // API 리턴값에 맞게 변경
          setSavedBracket(response.data.data);
          setShuffledTeams(response.data.data.teams || []);
          setEditablePositions(response.data.data.editablePositions || {});
          localStorage.setItem(localStorageKey, JSON.stringify(response.data.data.teams));
        }
      } catch (error) {
        console.error('대진표를 가져오는 중 오류 발생:', error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [sport]);

  const fetchSportId = useCallback(async () => {
    if (!sport) return;
    try {
      console.log('Fetching sport ID for:', sport);
      const response = await axios.get(`${API_BASE_URL}/api/sports/${sport}`);
      console.log('Sport ID API response:', response.data);
      if (response.data && response.data.id) {
        setSportId(response.data.id);
      } else {
        console.error('Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('스포츠 ID를 가져오는 중 오류 발생:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
    }
  }, [sport]);

  useEffect(() => {
    if (sport) {
      fetchTeams();
      fetchSavedBracket();
      fetchSportId();
    }
  }, [sport, fetchTeams, fetchSavedBracket, fetchSportId]);

  const handleShuffleTeams = async () => {
    console.log('handleShuffleTeams 함수 호출됨');
    const shuffled = [...teams].sort(() => Math.random() - 0.5);
    console.log('shuffledTeams 상태 업데이트:', shuffled);
    setShuffledTeams(shuffled);
    
    // DB에 저장
    try {
      const bracketData = {
        sport_id: sportId,
        teams: shuffled,
        editablePositions: savedBracket.editablePositions
      };
      const response = await axios.post(`${API_BASE_URL}/api/saveBracket/${sport}`, bracketData);
      
      if (response.data.success) {
        console.log('대진표가 성공적으로 DB에 저장되었습니다.');
        const updatedBracket = { ...savedBracket, teams: shuffled };
        setSavedBracket(updatedBracket);
      } else {
        console.error('대진표 저장 실패:', response.data.message);
      }
    } catch (error) {
      console.error('대진표 저장 중 오류 발생:', error);
      alert('대진표 저장 중 오류가 발생했습니다.');
    }
  };

  const handleEditPosition = (x, y, value) => {
    const updatedPositions = {
      ...savedBracket.editablePositions,
      [`${x},${y}`]: value
    };
    
    // savedBracket과 editablePositions를 동시에 업데이트
    const updatedBracket = { ...savedBracket, editablePositions: updatedPositions };
    setSavedBracket(updatedBracket);
    setEditablePositions(updatedPositions);
    
    // 로컬 스토리지에 저장
    localStorage.setItem(`savedBracket_${sport}`, JSON.stringify(updatedBracket));
  };

  const handleEditBracket = () => {
    if (isEditing) {
      // 수정 모드를 종료할 때 savedBracket을 업데이트
      const updatedBracket = { ...savedBracket, editablePositions };
      setSavedBracket(updatedBracket);
      localStorage.setItem(`savedBracket_${sport}`, JSON.stringify(updatedBracket));
    } else {
      // 수정 모드를 시작할 때 savedBracket의 editablePositions로 상태 초기화
      setEditablePositions(savedBracket.editablePositions || {});
    }
    setIsEditing(!isEditing);
  };

  const handleSaveBracket = async () => {
    console.log('handleSaveBracket 함수 호출됨');
    if (!sportId) {
      console.error('sportId가 없습니다.');
      return;
    }
    try {
      const bracketData = {
        sport_id: sportId,
        teams: shuffledTeams,
        editablePositions: editablePositions
      };
      console.log('저장할 데이터:', bracketData);
      const response = await axios.post(`${API_BASE_URL}/api/saveBracket/${sport}`, bracketData);
      console.log('서버 응답:', response.data);
      
      if (response.data.success) {
        const updatedBracket = { ...savedBracket, editablePositions };
        setSavedBracket(updatedBracket);
        localStorage.setItem(`savedBracket_${sport}`, JSON.stringify(updatedBracket));
        alert('대진표가 성공적으로 저장되었습니다.');
      } else {
        alert(`대진표 저장 실패: ${response.data.message || '알 수 없는 오류'}`);
      }
    } catch (error) {
      console.error('대진표 저장 중 오류 발생:', error);
      console.error('오류 상세 정보:', error.response?.data);
      alert(`대진표 저장 중 오류 발생: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDeleteBracket = () => {
    if (window.confirm('정말 대진표를 삭제하시겠습니까?')) {
      const emptyBracket = { teams: [], editablePositions: {} };
      setSavedBracket(emptyBracket);
      setShuffledTeams([]);
      setEditablePositions({});
      localStorage.removeItem(`savedBracket_${sport}`);
      alert('대진표가 삭제되었습니다.');
    }
  };

  const editablePositionsArray = [
    { x: 200, y: 75, label: '1차전 승자1' },
    { x: 200, y: 175, label: '1차전 승자2' },
    { x: 200, y: 275, label: '1차전 승자3' },
    { x: 200, y: 375, label: '1차전 승자4' },
    { x: 917, y: 75, label: '1차전 승자5' },
    { x: 917, y: 175, label: '1차전 승자6' },
    { x: 917, y: 275, label: '1차전 승자7' },
    { x: 917, y: 375, label: '1차전 승자8' },
    { x: 350, y: 125, label: '2차전 승자1' },
    { x: 350, y: 325, label: '2차전 승자2' },
    { x: 767, y: 125, label: '2차전 승자3' },
    { x: 767, y: 325, label: '2차전 승자4' },
    { x: 500, y: 225, label: '준결승 진출1' },
    { x: 617, y: 225, label: '준결승 진출2' },
    { x: 560, y: 400, label: '결승전 진출1' },
    { x: 200, y: 675, label: '1차전 승자9' },
    { x: 200, y: 775, label: '1차전 승자10' },
    { x: 200, y: 875, label: '1차전 승자11' },
    { x: 200, y: 975, label: '1차전 승자12' },
    { x: 917, y: 675, label: '1차전 승자13' },
    { x: 917, y: 775, label: '1차전 승자14' },
    { x: 917, y: 875, label: '1차전 승자15' },
    { x: 917, y: 975, label: '1차전 승자16' },
    { x: 350, y: 725, label: '2차전 승자5' },
    { x: 350, y: 925, label: '2차전 승자6' },
    { x: 767, y: 725, label: '2차전 승자7' },
    { x: 767, y: 925, label: '2차전 승자8' },
    { x: 500, y: 825, label: '준결승 진출3' },
    { x: 617, y: 825, label: '준결승 진출4' },
    { x: 560, y: 650, label: '결승전 진출2' }
  ];

  const leftColumnPositions = editablePositionsArray.filter(pos => pos.label.includes('1차전'));
  const rightColumnPositions = editablePositionsArray.filter(pos => !pos.label.includes('1차전'));

  return (
    <div>
      {isLoading ? (
        <p>로딩 중...</p>
      ) : (
        <>
          {isAdmin && (
            <>
              <button 
                onClick={handleShuffleTeams} 
                style={{ 
                  padding: '10px 20px', 
                  cursor: 'pointer', 
                  marginTop: '10px', 
                  marginBottom: '20px', 
                  marginLeft: '10px',
                  marginRight: '10px',
                  backgroundColor: '#ffc107', 
                  color: '#fff', 
                  border: 'none', 
                  borderRadius: '5px', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                }}
              >
                대진표 생성
              </button>
    
              <button 
                onClick={handleSaveBracket} 
                style={{ 
                  padding: '10px 20px', 
                  cursor: 'pointer', 
                  marginTop: '10px', 
                  marginBottom: '20px', 
                  marginLeft: '10px',
                  marginRight: '10px',
                  backgroundColor: '#28a745', 
                  color: '#fff', 
                  border: 'none', 
                  borderRadius: '5px', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                }}
              >
                대진표 저장
              </button>
    
              <button 
                onClick={handleDeleteBracket} 
                style={{ 
                  padding: '10px 20px', 
                  cursor: 'pointer', 
                  marginTop: '10px', 
                  marginBottom: '20px', 
                  marginLeft: '10px',
                  marginRight: '10px',
                  backgroundColor: '#dc3545', 
                  color: '#fff', 
                  border: 'none', 
                  borderRadius: '5px', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                }}
              >
                대진표 삭제
              </button>
    
              <button 
                onClick={handleEditBracket} 
                style={{ 
                  padding: '10px 20px', 
                  cursor: 'pointer', 
                  marginTop: '10px', 
                  marginBottom: '20px', 
                  marginLeft: '10px',
                  marginRight: '10px',
                  backgroundColor: '#007bff', 
                  color: '#fff', 
                  border: 'none', 
                  borderRadius: '5px', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                }}
              >
                {isEditing ? '수정 완료' : '대진표 수정'}
              </button>
            </>
          )}

          {isEditing && isAdmin && (
            <div style={{ 
              marginTop: '20px', 
              display: 'flex', 
              flexWrap: 'wrap', 
              justifyContent: 'space-between'
            }}>
              <div style={{ width: '48%' }}>
                {leftColumnPositions.map(({x, y, label}, index) => (
                  <div key={index} style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                    <span style={{ 
                      marginRight: '10px', 
                      minWidth: '150px', 
                      fontWeight: 'bold' 
                    }}>
                      {label}:
                    </span>
                    <input
                      type="text"
                      value={savedBracket.editablePositions[`${x},${y}`] || ''}
                      onChange={(e) => handleEditPosition(x, y, e.target.value)}
                      style={{ 
                        flex: 1, 
                        marginRight: '10px', 
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '4px'
                      }}
                      placeholder="팀 이름 입력"
                    />
                  </div>
                ))}
              </div>
              <div style={{ width: '48%' }}>
                {rightColumnPositions.map(({x, y, label}, index) => (
                  <div key={index} style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                    <span style={{ 
                      marginRight: '10px', 
                      minWidth: '150px', 
                      fontWeight: 'bold' 
                    }}>
                      {label}:
                    </span>
                    <input
                      type="text"
                      value={savedBracket.editablePositions[`${x},${y}`] || ''}
                      onChange={(e) => handleEditPosition(x, y, e.target.value)}
                      style={{ 
                        flex: 1, 
                        marginRight: '10px', 
                        padding: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '4px'
                      }}
                      placeholder="팀 이름 입력"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <TournamentBracketSVG 
              teams={savedBracket.teams} 
              editablePositions={savedBracket.editablePositions}
              sport={sport}
            />
          </div>
        </>
      )}
    </div>
  );
}