// src/components/Main.js
import React from 'react';
import { Link } from 'react-router-dom';

function Main() {
        const sports = [
        { name: '육상', path: '/athletics', image: require('../assets/athletics.png') },
        { name: '축구', path: '/soccer', image: require('../assets/soccer.png') },
        { name: '씨름', path: '/ssireum', image: require('../assets/ssireum.png') },
        { name: '테니스', path: '/tennis', image: require('../assets/tennis.png') },
        { name: '줄다리기', path: '/tugofwar', image: require('../assets/tugofwar.png') },
        { name: '배구', path: '/volleyball', image: require('../assets/volleyball.png') },
        { name: '게이트볼', path: '/gateball', image: require('../assets/gateball.png') },
        { name: '족구', path: '/jokgu', image: require('../assets/jokgu.png') },
        { name: '볼링', path: '/bowling', image: require('../assets/bowling.png') },
        { name: '배드민턴', path: '/badminton', image: require('../assets/badminton.png') },
        { name: '그라운드골프', path: '/groundgolf', image: require('../assets/groundgolf.png') },
        { name: '파크골프', path: '/parkgolf', image: require('../assets/parkgolf.png') },
    ];
    

    return (
        <div>
            {/* 포스터와 테이블을 한 줄에 나란히 배치 */}
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', marginBottom: '20px' }}>
                <Link to="/" style={{ marginRight: '20px', display: 'block', width: '100%' }}>
                    <img 
                        src={require('../assets/poster2.jpg')} 
                        alt="포스터" 
                        style={{ 
                            width: '100%',
                            maxWidth: '100%',
                            height: 'auto' 
                        }} 
                    />
                </Link>
            </div>

            {/* 스포츠 종목 버튼들 */}
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))'}}>
                {sports.map((sport, index) => (
                    <Link to={sport.path} key={index} style={{ textDecoration: 'none' }}>
                        <button 
                            style={{
                                width: '100%',
                                paddingBottom: '20%',  // 정사각형을 유지하기 위한 패딩
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            <div 
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <img 
                                    src={sport.image}  // 각 종목에 맞는 이미지 사용
                                    alt={sport.name} 
                                    style={{ maxHeight: '60%', marginBottom: '10px' }}  // 이미지 상하 패딩 조절
                                />
                                <span className="sport-name">{sport.name}</span>  {/* 텍스트 하단 배치 */}
                            </div>
                        </button>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Main;
