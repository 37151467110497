// src/components/contents/sports/GateballContents.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TeamIndex from '../TeamIndex'; // TeamIndex 컴포넌트 추가
import Ranking from '../Ranking'; // Ranking 컴포넌트 추가
import GroupBracket from '../GroupBracket'; // GroupBracket 컴포넌트 추가

function GateballContents({ isAdmin }) {
    const [content, setContent] = useState(null);
    const [boxHeight, setBoxHeight] = useState('auto');
    const tableRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (tableRef.current) {
            setBoxHeight(`${tableRef.current.clientHeight}px`);
        }
    }, []);

    const eventDetailsTable = (
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px' }}>
            {/* 게이트볼 이미지가 삽입될 정사각형 칸 */}
            <div 
                style={{ 
                    width: boxHeight, 
                    height: boxHeight, 
                    marginRight: '20px',
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center', // 이미지와 텍스트를 중앙에 배치
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    padding: '10px', // 패딩 추가
                    boxSizing: 'border-box', // 패딩이 크기에 영향을 주지 않도록 설정
                }}
            >
                <img 
                    src={require('../../../assets/gateball.png')}
                    alt="게이트볼" 
                    style={{ maxHeight: '70%', marginTop: '10px', marginBottom: '10px' }}
                />
                <span>게이트볼</span>
            </div>

            {/* 종목 설명 표 */}
            <table ref={tableRef} style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                    <tr>
                        <td style={{ fontWeight: 'bold', paddingRight: '20px', border: '1px solid #ccc', padding: '10px' }}>진행</td>
                        <td style={{ border: '1px solid #ccc', padding: '10px' }}>천안시게이트볼협회</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', paddingRight: '20px', border: '1px solid #ccc', padding: '10px' }}>경기 일자</td>
                        <td style={{ border: '1px solid #ccc', padding: '10px' }}>2024. 10. 26</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', paddingRight: '20px', border: '1px solid #ccc', padding: '10px' }}>경기 장소</td>
                        <td style={{ border: '1px solid #ccc', padding: '10px' }}>천안신방체육관 게이트볼장</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        <div style={{ padding: '20px', position: 'relative' }}>
            {/* 상단의 메인으로 가기 버튼 */}
            <button 
                onClick={() => navigate('/main')} 
                style={{ 
                    marginBottom: '20px',
                    padding: '10px 20px', 
                    cursor: 'pointer', 
                    backgroundColor: '#f8f8f8', 
                    border: '1px solid #ccc', 
                    borderRadius: '4px',
                    zIndex: 1
                }}
            >
                메인으로
            </button>

            {/* 종목 설명 표 */}
            {eventDetailsTable}

            {/* 대진표, 참가팀, 순위 버튼들 */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <button 
                    style={{ flex: 1, marginRight: '10px', padding: '10px', cursor: 'pointer' }}
                    onClick={() => setContent('대진표')}
                >
                    대진표
                </button>
                <button 
                    style={{ flex: 1, marginRight: '10px', padding: '10px', cursor: 'pointer' }}
                    onClick={() => setContent('참가팀')}
                >
                    참가팀
                </button>
                <button 
                    style={{ flex: 1, padding: '10px', cursor: 'pointer' }}
                    onClick={() => setContent('순위')}
                >
                    순위
                </button>
            </div>

            {/* 선택한 내용이 표시되는 영역 */}
            
            {/* 대진표 구현 완료 */}
            <div style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '8px' }}>
                {content === '대진표' && (
                    <GroupBracket isAdmin={isAdmin} sport="gateball" rows={4} columns={8} />  // 조별 최대 팀수 : 8, 최대 조 갯수 : 4
                )}

            {/* 참가팀 구현 완료 */}
                {content === '참가팀' && (
                    <TeamIndex isAdmin={isAdmin} sport="gateball" />
                )}
            {/* 순위 구현 완료 */}
                {content === '순위' && (
                    <Ranking isAdmin={isAdmin} sport="gateball" />
                )}
                {!content && '내용을 선택하세요.'} {/* 기본적으로 내용이 선택되지 않았을 때 표시 */}
            </div>
        </div>
    );
}
export default GateballContents;
