// src/components/contents/sports/ParkGolfContents.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import TeamIndex from '../TeamIndex'; // TeamIndex 컴포넌트 추가
import Ranking from '../Ranking'; // Ranking 컴포넌트 추가
const images = require.context('../../../assets', true);

function ParkGolfContents({ isAdmin }) {
    const [content, setContent] = useState(null);
    const [boxHeight, setBoxHeight] = useState('auto');
    const tableRef = useRef(null);
    const navigate = useNavigate();
    // const parkGolfBracketImage = null; // 이미지 import할 경우, 이 줄을 주석

    useEffect(() => {
        if (tableRef.current) {
            setBoxHeight(`${tableRef.current.clientHeight}px`);
        }
    }, []);

    const eventDetailsTable = (
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px' }}>
            {/* 파크골프 이미지가 삽입될 정사각형 칸 */}
            <div 
                style={{ 
                    width: boxHeight, 
                    height: boxHeight, 
                    marginRight: '20px',
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center', // 이미지와 텍스트를 중앙에 배치
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    padding: '10px', // 패딩 추가
                    boxSizing: 'border-box', // 패딩이 크기에 영향을 주지 않도록 설정
                }}
            >
                <img 
                    src={require('../../../assets/parkgolf.png')}
                    alt="파크골프" 
                    style={{ maxHeight: '70%', marginTop: '10px', marginBottom: '10px' }}
                />
                <span>파크골프</span>
            </div>

            {/* 종목 설명 표 */}
            <table ref={tableRef} style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tbody>
                    <tr>
                        <td style={{ fontWeight: 'bold', paddingRight: '20px', border: '1px solid #ccc', padding: '10px' }}>진행</td>
                        <td style={{ border: '1px solid #ccc', padding: '10px' }}>천안시파크골프협회</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', paddingRight: '20px', border: '1px solid #ccc', padding: '10px' }}>경기 일자</td>
                        <td style={{ border: '1px solid #ccc', padding: '10px' }}>2024. 10. 26</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', paddingRight: '20px', border: '1px solid #ccc', padding: '10px' }}>경기 장소</td>
                        <td style={{ border: '1px solid #ccc', padding: '10px' }}>파크골프 경기장</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

    return (
        <div style={{ padding: '20px', position: 'relative' }}>
            {/* 상단의 메인으로 가기 버튼 */}
            <button 
                onClick={() => navigate('/main')} 
                style={{ 
                    marginBottom: '20px',
                    padding: '10px 20px', 
                    cursor: 'pointer', 
                    backgroundColor: '#f8f8f8', 
                    border: '1px solid #ccc', 
                    borderRadius: '4px',
                    zIndex: 1
                }}
            >
                메인으로
            </button>

            {/* 종목 설명 표 */}
            {eventDetailsTable}

            {/* 대진표, 참가팀, 순위 버튼들 */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <button 
                    style={{ flex: 1, marginRight: '10px', padding: '10px', cursor: 'pointer' }}
                    onClick={() => setContent('대진표')}
                >
                    대진표
                </button>
                <button 
                    style={{ flex: 1, marginRight: '10px', padding: '10px', cursor: 'pointer' }}
                    onClick={() => setContent('참가팀')}
                >
                    참가팀
                </button>
                <button 
                    style={{ flex: 1, padding: '10px', cursor: 'pointer' }}
                    onClick={() => setContent('순위')}
                >
                    순위
                </button>
            </div>

            {/* 선택한 내용이 표시되는 영역 */}

            {/* 대진표 구현 완료 */}
            <div style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '8px' }}>
                {content === '대진표' && (
                    <div>
                        {['01', '02', '03', '04', '05'].map((num) => {
                            const imagePath = `./park_golf_${num}.jpg`;
                            return (
                                <div key={num} style={{ margin: '20px 0' }}>
                                    {images(imagePath) ? (
                                        <img 
                                            src={images(imagePath)}
                                            alt={`파크골프 대진표 ${num}`}
                                            style={{ 
                                                maxWidth: '100%', 
                                                height: 'auto', 
                                                display: 'block', 
                                                margin: '0 auto'
                                            }} 
                                        />
                                    ) : (
                                        <p style={{ 
                                            textAlign: 'center', 
                                            fontSize: '16px', 
                                            color: '#666'
                                        }}>
                                            준비중입니다.
                                        </p>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            {/* 참가팀 구현 완료 */}
                {content === '참가팀' && (
                    <TeamIndex isAdmin={isAdmin} sport="park_golf" />
                )}
            {/* 순위 구현 완료 */}
                {content === '순위' && (
                    <Ranking isAdmin={isAdmin} />
                )}
                {!content && '내용을 선택하세요.'} {/* 기본적으로 내용이 선택되지 않았을 때 표시 */}
            </div>
        </div>
    );
}

export default ParkGolfContents;
