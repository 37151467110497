import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Main from './components/Main';
import Login from './components/Login';
import Layout from './components/Layout';
import SoccerContents from './components/contents/sports/SoccerContents';
import AthleticsContents from './components/contents/sports/AthleticsContents';
import SsireumContents from './components/contents/sports/SsireumContents';
import TennisContents from './components/contents/sports/TennisContents';
import TugOfWarContents from './components/contents/sports/TugOfWarContents';
import VolleyballContents from './components/contents/sports/VolleyballContents';
import GateballContents from './components/contents/sports/GateballContents';
import JokguContents from './components/contents/sports/JokguContents';
import BowlingContents from './components/contents/sports/BowlingContents';
import BadmintonContents from './components/contents/sports/BadmintonContents';
import GroundGolfContents from './components/contents/sports/GroundGolfContents';
import ParkGolfContents from './components/contents/sports/ParkGolfContents';

function App() {
    const [isAdmin, setIsAdmin] = useState(false);

    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home isAdmin={isAdmin} setIsAdmin={setIsAdmin} />} />
                    <Route path="/main" element={<Main isAdmin={isAdmin} />} />
                    <Route path="/login" element={<Login setIsAdmin={setIsAdmin} />} />
                    <Route path="/soccer" element={<SoccerContents isAdmin={isAdmin} />} />
                    <Route path="/athletics" element={<AthleticsContents isAdmin={isAdmin} />} />
                    <Route path="/ssireum" element={<SsireumContents isAdmin={isAdmin} />} />
                    <Route path="/tennis" element={<TennisContents isAdmin={isAdmin} />} />
                    <Route path="/tugofwar" element={<TugOfWarContents isAdmin={isAdmin} />} />
                    <Route path="/volleyball" element={<VolleyballContents isAdmin={isAdmin} />} />
                    <Route path="/gateball" element={<GateballContents isAdmin={isAdmin} />} />
                    <Route path="/jokgu" element={<JokguContents isAdmin={isAdmin} />} />
                    <Route path="/bowling" element={<BowlingContents isAdmin={isAdmin} />} />
                    <Route path="/badminton" element={<BadmintonContents isAdmin={isAdmin} />} />
                    <Route path="/groundgolf" element={<GroundGolfContents isAdmin={isAdmin} />} />
                    <Route path="/parkgolf" element={<ParkGolfContents isAdmin={isAdmin} />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
