import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Home({ isAdmin, setIsAdmin }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        setIsAdmin(false);
        alert('로그아웃 되었습니다.');
        navigate('/');
    };

    return (
        <div style={{ position: 'relative', padding: '20px' }}>
            {/* 우측 상단에 관리자 로그인/로그아웃 버튼 배치 */}
            <div style={{ position: 'absolute', top: '20px', right: '20px' }}>
                {isAdmin ? (
                    <button 
                        onClick={handleLogout} 
                        style={{ fontSize: '10px', padding: '10px 10px', cursor: 'pointer' }}
                    >
                        로그아웃
                    </button>
                ) : (
                    <Link to="/login">
                        <button style={{ fontSize: '10px', padding: '10px 10px', cursor: 'pointer' }}>
                            관리자
                        </button>
                    </Link>
                )}
            </div>

            {/* 대회 제목 */}
            <h2 style={{ 
                textAlign: 'center', 
                marginTop: '40px', 
                fontSize: '5vw'
            }}>
                2024천안시민한마음체육대회
            </h2>

            {/* 포스터 이미지 */}
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 3vw', marginTop: '40px' }}>
                <Link to="/main">
                    <img 
                        src={require('../assets/poster.png')} 
                        alt="포스터" 
                        style={{ 
                            maxWidth: '100%', 
                            height: 'auto', 
                            cursor: 'pointer',
                            }} 
                    />
                </Link>
            </div>
        </div>
    );
}

export default Home;
